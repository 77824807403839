import useSWR from "swr";
import useSWRMutation from "swr/mutation";
export function useActionQuery(action) {
    for(var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++)args[_key - 1] = arguments[_key];
    return useSWR({
        name: action.name,
        args
    }, ()=>action(...args));
}
export function useActionWithOptions(action, config) {
    for(var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++)args[_key - 2] = arguments[_key];
    return useSWR({
        name: action.name,
        args
    }, ()=>action(...args), config);
}
export function useSuspenseActionQuery(action) {
    for(var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++)args[_key - 1] = arguments[_key];
    return useSWR({
        name: action.name,
        args
    }, ()=>action(...args), {
        suspense: !0
    });
}
export function useActionMutation(action) {
    for(var _len = arguments.length, params = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++)params[_key - 1] = arguments[_key];
    return useSWRMutation({
        name: action.name
    }, (key, param)=>{
        let { arg } = param;
        return action(...params, ...arg);
    });
}
