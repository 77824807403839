import { useCallback, useState, useTransition } from "react";
export function useTransitionStateHook() {
    let [isPending, startTransition] = useTransition();
    return [
        isPending,
        useCallback((defaultValue)=>{
            let [value, setValue] = useState(defaultValue);
            return [
                value,
                useCallback((action)=>{
                    startTransition(()=>setValue(action));
                }, [])
            ];
        }, [
            startTransition
        ])
    ];
}
