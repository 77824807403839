import { createSvgIcons } from "$components/common/SvgPathIcon";
export const AttributeIcons = createSvgIcons({
    INT: "m3 19 9-15c3.3 4.5 4.7 6.6 4.9 8.5s.1 5.5-3.9 5.5-3-5-1-5m7.7 4.7 1 1.7",
    PHY: "m3.4 15.7c2.4 4 8.3 4.4 11.3 2.3s6.1-4.7 5.8-13m-11 7.4 4.2-5m-5.5-2.3a.1.1 0 000 1 .1.1 0 000-1",
    COM: "m3.7 20.5 2.5-3.5c-1.4-2.3-1.6-3.7-1.6-6.3s2.2-7.2 8.3-7.3 7.2 5.4 7.2 7.5-1.1 6.2-4.4 6.3m-3.3-6.4a.1.1 0 000 1 .1.1 0 000-1",
    SAN: "m8.1 18.6-4.7 0c2.4-9.4 5.3-13.1 10.2-13.1s7.5 5 7.1 8.1-2.8 4.9-5.2 4.8-4.5-1.3-4.5-5.9l3.2 0",
    KNO: "m8.6 4.7c-3.1.7-5.1 3.6-5.1 7.8s2.1 6.8 7.1 7l3.8-15.1c4.3.9 6.2 4 6.2 7.8s-1.6 7-5 7",
    LDR: "m11.4 3.6h7.6v4.7c-7.2.1-11 4.9-14.2 12m11-5.4 3 4.6",
    Star: "m12 0-3 9-9 3 9 3 3 9 3-9 9-3-9-3z"
});
