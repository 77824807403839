import { useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";
export function useToastDismiss() {
    let ref = useRef([]);
    return useEffect(()=>()=>{
            ref.current.map((key)=>toast.dismiss(key));
        }, []), useCallback((id)=>{
        ref.current.push(id);
    }, []);
}
