export const variants = {
    enter: {
        y: 20,
        opacity: 0
    },
    center: {
        y: 0,
        opacity: 1,
        height: "auto",
        transition: {
            bounce: 0
        },
        transitionEnd: {
            pointerEvents: "all"
        }
    },
    exit: {
        opacity: 0,
        transitionEnd: {
            pointerEvents: "none"
        }
    }
};
export const SimpleVariants = {
    enter: {
        y: 20,
        opacity: 0
    },
    center: {
        y: 0,
        opacity: 1,
        transition: {
            bounce: 0
        },
        transitionEnd: {
            pointerEvents: "all"
        }
    },
    exit: {
        opacity: 0,
        transitionEnd: {
            pointerEvents: "none"
        }
    }
};
export const ButtonVariants = {
    enter: {
        opacity: 0
    },
    center: {
        opacity: 1,
        transition: {
            bounce: 0
        },
        transitionEnd: {
            pointerEvents: "all"
        }
    },
    exit: {
        opacity: 0,
        transitionEnd: {
            pointerEvents: "none"
        }
    }
};
export const PanelVariants = {
    center: {
        transition: {
            staggerChildren: 0.05
        },
        transitionEnd: {
            pointerEvents: "all"
        }
    },
    exit: {
        transition: {
            staggerChildren: 0.05,
            staggerDirection: -1,
            when: "afterChildren"
        },
        transitionEnd: {
            pointerEvents: "none",
            translateX: "100%"
        }
    }
};
