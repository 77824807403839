var TabBarIndex, ModalIndex;
import { transactionalRead, transactionalWrite } from "tuple-database";
(TabBarIndex = TabBarIndex1 || (TabBarIndex1 = {})).selected = transactionalRead()((tx)=>{
    var _tx_get;
    return null != (_tx_get = tx.get([
        "selected"
    ])) ? _tx_get : 0;
}), TabBarIndex.selectIndex = transactionalWrite()((tx, idx)=>{
    tx.set([
        "selected"
    ], idx);
}), (ModalIndex = ModalIndex1 || (ModalIndex1 = {})).mode = transactionalRead()((tx)=>{
    var _tx_get;
    return null != (_tx_get = tx.get([
        "mode"
    ])) ? _tx_get : "default";
}), ModalIndex.setMode = transactionalWrite()((tx, mode)=>{
    tx.set([
        "mode"
    ], mode);
});
var TabBarIndex1, ModalIndex1;
export { TabBarIndex1 as TabBarIndex, ModalIndex1 as ModalIndex };
