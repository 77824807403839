import { useMemo, useReducer } from "react";
export function useManualReload(fn, dependencies) {
    void 0 === dependencies && (dependencies = []);
    let [gen, reload] = useReducer((x)=>x + 1, 0);
    return [
        useMemo(fn, [
            gen,
            ...dependencies
        ]),
        reload
    ];
}
