export function manhattan(a, b) {
    return Math.abs(a.x - b.x) + Math.abs(a.y - b.y);
}
let TracePoint = class TracePoint {
    get index() {
        return this.x + "-" + this.y;
    }
    get f() {
        return this.g + this.h + this.weight;
    }
    createPath(path) {
        return void 0 === path && (path = []), path.unshift({
            x: this.x,
            y: this.y
        }), this.parentNode && this.parentNode.createPath(path), path;
    }
    constructor(x, y, weight, h, g, parentNode){
        this.x = x, this.y = y, this.weight = weight, this.h = h, this.g = g, this.parentNode = parentNode, this.visited = !1;
    }
};
TracePoint.Builder = class {
    build(point, parent) {
        let weight = this.getWeight(point.x, point.y);
        if (null == weight || weight >= 1) return;
        let h = manhattan(point, this.dest), newPoint = new TracePoint(point.x, point.y, weight, h, null == parent ? 0 : parent.g + h, parent), oldPoint = this.cache.get(newPoint.index);
        return oldPoint && (oldPoint.f < newPoint.f || oldPoint.f === newPoint.f && oldPoint.h < newPoint.h) ? oldPoint : (this.cache.set(newPoint.index, newPoint), newPoint);
    }
    getSmallest() {
        return [
            ...this.cache.values()
        ].reduce((current, node)=>!0 === node.visited ? current : null == current ? node : current.f < node.f ? current : current.f > node.f ? node : current.h < node.h ? current : node, void 0);
    }
    getCachedPointAt(x, y) {
        return this.cache.get(x + "-" + y);
    }
    constructor(getWeight, dest){
        this.getWeight = getWeight, this.dest = dest, this.cache = new Map();
    }
};
export function findPath(gridInput, start, dest) {
    let builder = new TracePoint.Builder(gridInput, dest), current = builder.build(start);
    for(; null != current && (current.x !== dest.x || current.y !== dest.y);)current.visited = !0, [
        {
            x: current.x - 1,
            y: current.y
        },
        {
            x: current.x + 1,
            y: current.y
        },
        {
            x: current.x,
            y: current.y - 1
        },
        {
            x: current.x,
            y: current.y + 1
        }
    ].forEach((coords)=>builder.build(coords, current)), current = builder.getSmallest();
    let destPoint = builder.getCachedPointAt(dest.x, dest.y);
    return null == destPoint ? void 0 : destPoint.createPath();
}
