function shouldNotBeInvokedBeforeMount() {
    throw Error("foxact: the stablized handler cannot be invoked before the component has mounted.");
}
import { useCallback, useInsertionEffect, useRef, useState } from "react";
export function useButtonAction(callback) {
    let latestRef = useRef(shouldNotBeInvokedBeforeMount), lockRef = useRef(!1), [loading, setLoading] = useState(!1);
    return useInsertionEffect(()=>{
        latestRef.current = callback;
    }, [
        callback
    ]), [
        useCallback((e)=>{
            if (e.stopPropagation(), lockRef.current) return;
            let ret = (0, latestRef.current)(e);
            lockRef.current = !0, setLoading(!0), ret.finally(()=>setLoading(lockRef.current = !1));
        }, []),
        loading
    ];
}
