import { useLayoutEffect, useRef } from "react";
let cache = new Map();
export function useMeasuredSize(prefix) {
    let ref = useRef(null);
    return useLayoutEffect(()=>{
        let observer = cache.get(prefix);
        observer || (observer = new ResizeObserver((entries)=>{
            for (let { target, contentRect } of entries)target instanceof HTMLElement && (target.style.setProperty("--" + prefix + "-width", "" + contentRect.width + "px"), target.style.setProperty("--" + prefix + "-height", "" + contentRect.height + "px"));
        }), cache.set(prefix, observer));
        let target = ref.current;
        if (target) return observer.observe(target), ()=>observer.unobserve(target);
    }, [
        prefix
    ]), ref;
}
