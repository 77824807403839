export function getCabinEventRelatedPlayers(event) {
    switch(event.type){
        case "conversation":
        case "thinking":
            return event.players;
        case "introspection":
            return [
                event.player
            ];
    }
    return [];
}
export function getCabinEventPlayerMap(events) {
    let map = new Map();
    for (let event of events)for (let player of getCabinEventRelatedPlayers(event))map.set(player, event);
    return map;
}
export function findCabinEventForPlayer(events, target) {
    for (let event of events)for (let player of getCabinEventRelatedPlayers(event))if (player === target) return event;
}
