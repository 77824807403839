export function shadow(base, extra) {
    return Object.create(base, Object.getOwnPropertyDescriptors(extra));
}
export function shadowRef(baseRef, extra) {
    return new Proxy(extra, {
        get (target, prop) {
            var // @ts-ignore
            _baseRef_current;
            return prop in target ? // @ts-ignore
            target[prop] : null == (_baseRef_current = baseRef.current) ? void 0 : _baseRef_current[prop];
        }
    });
}
