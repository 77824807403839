var TempPlayers;
import { transactionalRead, transactionalReadWrite } from "tuple-database";
(TempPlayers = TempPlayers1 || (TempPlayers1 = {})).reset = transactionalReadWrite()((tx, list)=>{
    tx.scan({
        prefix: []
    }).forEach((param)=>{
        let { key } = param;
        return tx.remove(key);
    }), list.forEach((id)=>tx.set([
            id
        ], !0));
}), TempPlayers.get = transactionalRead()((tx)=>tx.scan({
        prefix: []
    }).map((param)=>{
        let { key } = param;
        return key[0];
    })), TempPlayers.joinWithLimit = transactionalReadWrite()((tx, id, limit)=>{
    if (tx.set([
        id
    ], !0), tx.scan({
        prefix: []
    }).length > limit) throw Error("达到小屋容量上限，请手动移除旧的角色再加入。");
}), TempPlayers.count = transactionalRead()((tx)=>tx.scan({
        prefix: []
    }).length), TempPlayers.has = transactionalRead()((tx, id)=>tx.exists([
        id
    ])), TempPlayers.toggle = transactionalReadWrite()((tx, id)=>tx.exists([
        id
    ]) ? tx.remove([
        id
    ]) : tx.set([
        id
    ], !0));
var TempPlayers1;
export { TempPlayers1 as TempPlayers };
