export function getRandomItem(arr, getWeight) {
    return arr[getRandomIndex(arr, getWeight)];
}
export function getRandomIndex(arr, getWeight) {
    let weights = arr.map(null != getWeight ? getWeight : ()=>1), sum = weights.reduce((a, b)=>a + b, 0), probabilities = weights.map((w)=>w / sum).reduce((accumulator, value)=>[
            ...accumulator,
            accumulator[accumulator.length - 1] + value
        ], [
        0
    ]);
    probabilities.shift();
    let random = Math.random();
    return probabilities.findIndex((p)=>p > random);
}
export function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
