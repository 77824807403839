import { useState } from "react";
import { withAbort } from "./abort";
import { createEventSource } from "./eventsource";
import { useVisibleEffect } from "./useVisibilityChange";
export function useCabinEvents(id) {
    let [events, setEvents] = useState([]);
    return useVisibleEffect(()=>withAbort((signal)=>{
            createEventSource("/events/cabin/" + id, signal).addEventListener("message", (param)=>{
                let { data } = param;
                return setEvents(JSON.parse(data));
            }, {
                signal
            });
        })), events;
}
