var BatteryState, CabinState;
import { transactionalRead, transactionalReadWrite, transactionalWrite } from "tuple-database";
(BatteryState = BatteryState1 || (BatteryState1 = {})).enabled = transactionalRead()((tx)=>tx.exists([
        "enabled"
    ])), BatteryState.energy = transactionalRead()((tx)=>{
    var _tx_get;
    return null != (_tx_get = tx.get([
        "energy"
    ])) ? _tx_get : "00:00:00";
}), BatteryState.hasEnergy = transactionalRead()((tx)=>"00:00:00" != tx.get([
        "energy"
    ]) || "00:00:00" != tx.get([
        "premiumEnergy"
    ])), BatteryState.premiumEnergy = transactionalRead()((tx)=>{
    var _tx_get;
    return null != (_tx_get = tx.get([
        "premiumEnergy"
    ])) ? _tx_get : "00:00:00";
}), BatteryState.updateEnergy = transactionalWrite()((tx, energy, premiumEnergy)=>{
    tx.set([
        "energy"
    ], energy), tx.set([
        "premiumEnergy"
    ], premiumEnergy);
}), BatteryState.toggleEnabled = transactionalWrite()((tx, enabled)=>{
    enabled ? tx.set([
        "enabled"
    ], !0) : tx.remove([
        "enabled"
    ]);
}), (CabinState = CabinState1 || (CabinState1 = {})).updatePlayerList = transactionalReadWrite()((tx, list)=>{
    for (let { key } of tx.scan({
        prefix: [
            "player"
        ]
    }))tx.remove(key);
    for (let { key } of tx.scan({
        prefix: [
            "player-enabled"
        ]
    }))tx.remove(key);
    for (let { id, owner, enabled } of list)tx.set([
        "player",
        id
    ], owner), enabled && tx.set([
        "player-enabled",
        id
    ], !0);
}), CabinState.hasEnabledPlayers = transactionalRead()((tx)=>tx.scan({
        prefix: [
            "player-enabled"
        ]
    }).length > 0), CabinState.enabledPlayers = transactionalRead()((tx)=>tx.scan({
        prefix: [
            "player-enabled"
        ]
    }).map((x)=>x.key[1])), CabinState.enabledPlayerCount = transactionalRead()((tx)=>tx.scan({
        prefix: [
            "player-enabled"
        ]
    }).length), CabinState.hasSelectedPlayer = transactionalRead()((tx)=>tx.exists([
        "selected-player"
    ])), CabinState.selectedPlayer = transactionalRead()((tx)=>tx.get([
        "selected-player"
    ])), CabinState.selectPlayer = transactionalWrite()((tx, id)=>{
    id ? tx.set([
        "selected-player"
    ], id) : tx.remove([
        "selected-player"
    ]);
}), CabinState.playerJoined = transactionalRead()((tx, id)=>tx.exists([
        "player",
        id
    ])), CabinState.checkPlayerEnabled = transactionalRead()((tx, id)=>tx.exists([
        "player-enabled",
        id
    ])), CabinState.hostPlayers = transactionalRead()((tx, myid)=>tx.scan({
        prefix: [
            "player"
        ]
    }).filter((x)=>x.value === myid).map((x)=>x.key[1])), CabinState.guestPlayers = transactionalRead()((tx, myid)=>{
    let ids = tx.scan({
        prefix: [
            "player"
        ]
    }).filter((x)=>x.value != myid).map((x)=>({
            id: x.key[1],
            owner: x.value
        })), enabled = tx.scan({
        prefix: [
            "player-enabled"
        ]
    }).map((x)=>x.key[1]);
    return ids.map((param)=>{
        let { id, owner } = param;
        return {
            id,
            enabled: enabled.includes(id),
            owner
        };
    });
});
var BatteryState1, CabinState1;
export { BatteryState1 as BatteryState, CabinState1 as CabinState };
