export function getFaceDir(start, dest) {
    let diff = {
        x: dest.x - start.x,
        y: dest.y - start.y
    }, deg = Math.atan2(diff.y, diff.x) / Math.PI * 180;
    return deg >= 45 && deg <= 135 ? "front" : deg <= -135 || deg >= 135 ? "left" : deg >= -45 && deg <= 45 ? "right" : "back";
}
export function getNextPosition(x, y, face) {
    switch(face){
        case "front":
            return [
                x,
                y + 1
            ];
        case "left":
            return [
                x - 1,
                y
            ];
        case "right":
            return [
                x + 1,
                y
            ];
        case "back":
            return [
                x,
                y - 1
            ];
    }
}
export function getPrevPosition(x, y, face) {
    switch(face){
        case "front":
            return [
                x,
                y - 1
            ];
        case "left":
            return [
                x + 1,
                y
            ];
        case "right":
            return [
                x - 1,
                y
            ];
        case "back":
            return [
                x,
                y + 1
            ];
    }
}
export function getRelativeNeighborPoints(x, y, face) {
    switch(face){
        case "front":
            return [
                [
                    x,
                    y + 1,
                    "front"
                ],
                [
                    x + 1,
                    y,
                    "right"
                ],
                [
                    x - 1,
                    y,
                    "left"
                ],
                [
                    x,
                    y - 1,
                    "back"
                ]
            ];
        case "left":
            return [
                [
                    x - 1,
                    y,
                    "left"
                ],
                [
                    x,
                    y + 1,
                    "front"
                ],
                [
                    x,
                    y - 1,
                    "back"
                ],
                [
                    x + 1,
                    y,
                    "right"
                ]
            ];
        case "right":
            return [
                [
                    x + 1,
                    y,
                    "right"
                ],
                [
                    x,
                    y - 1,
                    "back"
                ],
                [
                    x,
                    y + 1,
                    "front"
                ],
                [
                    x - 1,
                    y,
                    "left"
                ]
            ];
        case "back":
            return [
                [
                    x,
                    y - 1,
                    "back"
                ],
                [
                    x - 1,
                    y,
                    "left"
                ],
                [
                    x + 1,
                    y,
                    "right"
                ],
                [
                    x,
                    y + 1,
                    "front"
                ]
            ];
    }
}
