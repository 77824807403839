import { useEffect, useRef, useState } from "react";
import ArrayMorph from "array-morph";
export const useArrayMorph = (value, delay)=>{
    let [morph] = useState(()=>new ArrayMorph([])), handler = useRef(0), [result, setResult] = useState([]);
    return useEffect(()=>(morph.update(null != value ? value : []), handler.current && clearInterval(handler.current), handler.current = setInterval(()=>{
            let { value, done } = morph.next();
            setResult(value), done && (clearInterval(handler.current), handler.current = 0);
        }, delay), ()=>{
            handler.current && clearInterval(handler.current), handler.current = 0;
        }), [
        value
    ]), result;
};
